
$primary: #eab200;
$secondary: #2f2d38;
$danger: #dc3545;
$mid: #ebebee;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger
);


@import 'bootstrap/scss/bootstrap.scss';

$navbarHeight: 70px;

html, body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

h3, h4, h5 {
  color: $primary;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

#content-container {
  flex-grow: 1;
}

h1.page-title {
  font-size: inherit;
  display: inline;
}

#program-title {
  padding-top: 3rem;
  background-color: $mid;

  h2 {
    margin-bottom: 0;
  }

  #program-title-anchor {
    position: relative;
    top: -$navbarHeight;
  }
}

h2 {
  text-align: center;
  margin-bottom: 3rem;
}

header {
  background-color: $white;
  position: sticky;
  top: 0;
  box-shadow: 0px 3px 3px lightgray;
  z-index: 999999;

  .link-to-login {
    color: $primary;

    &:hover {
      color: var(--bs-link-hover-color);
    }
  }
}

footer {
  background-color: $dark;
  color: $light;
  text-align: right;
  padding-right: 2rem;
}

.bg-mid {
  background-color: $mid;
}

section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

section.links {

  padding-top: 2rem;
  padding-bottom: 2rem;

  .link-icon {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.25rem;
    justify-content: center;
    border-radius: 5px;
    min-height: 165px;
  }
}

.approved-mentors h3 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.person {

  @media (max-width: 767px) {
    &:nth-child(even) {
      background: $mid;
    }

    .person-content-container {
      flex-direction: column;

      .person-name {
        position: initial !important;
      }

      img {
        align-self: center;
      }
    }
  }
  @media (min-width: 768px) {
    &:nth-child(odd) {
      .person-content-container {
        flex-direction: row;
      }
    }

    &:nth-child(even) {
      background: $mid;
      .person-content-container {
        flex-direction: row-reverse;
      }
    }
  }

  .person-content-container {
    display: flex;
    flex-direction: row;
    position: relative;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    img {
      object-fit: none;
      object-position: center;
    }

    .person-name {
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }

    .person-blurb {
      margin-top: 50px;
      margin-left: 10px;
      margin-right: 10px;
      flex-grow: 1;
    }
  }
}